import React, { useEffect, useState } from 'react';

import styles from './Toast.module.scss';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import sendEventToDataLayer from '../../utils/sendEventToDataLayer';

const Toast = ({
  stateName,
  stateStatus,
  onClose,
  id,
  navigate,
  autoClose = false,
  allowFunc = false,
  func,
  datalayer,
}) => {
  // TODO allowFunc  kontrol edilip silinlmeli
  const { t } = useTranslation();
  const isSuccess = useSelector((state) => state[stateName].isSuccess);
  const validationResponse = useSelector((state) => state[stateName].validationMessage);
  // TODO toast için istek tipine göre succes beklenmeli.
  // const requestStatus = useSelector((state) => state[stateName].loading[stateStatus]);
  const [handleError, setHandleError] = useState('');
  const [stateId, setStateId] = useState(null);

  const handleOnClose = (close = false) => {
    onClose(close);
    setHandleError('');
    setStateId(null);
    if (navigate !== undefined && !close) {
      navigate();
    }
  };

  useEffect(() => {
    if (isSuccess !== null) {
      if (isSuccess && validationResponse !== '') {
        setHandleError(
          <div>
            {t(`${validationResponse}`)
              ?.split('{br}')
              ?.map((item, index) => (
                <span
                  key={index}
                  className='d-flex position-relative justify-content-center'
                  style={{ maxWidth: '550px' }}
                >
                  <strong>{item}</strong>
                </span>
              ))}
          </div>
        );
        if (allowFunc) {
          func();
        }
      } else if (!isSuccess && validationResponse !== '') {
        setHandleError(
          <div>
            {t(`${validationResponse}`)
              ?.split('{br}')
              ?.map((item, index) => (
                <span
                  key={index}
                  className='d-flex position-relative justify-content-center'
                  style={{ maxWidth: '550px' }}
                >
                  <strong>
                    {item.includes('required')
                      ? item.split('required').map((part, i) => (
                          <React.Fragment key={i}>
                            {i > 0 && <span style={{ color: 'red' }}>{t('required')}</span>}
                            {part}
                          </React.Fragment>
                        ))
                      : item}
                  </strong>
                </span>
              ))}
          </div>
        );
      }
      setStateId(id);
    }
  }, [isSuccess, validationResponse]);

  useEffect(() => {
    const systemError = validationResponse?.includes('.system');
    let style = validationResponse?.length < 15 ? styles.mediumToastStyle : styles.largeToastStyle;
    if (handleError !== '' && !isSuccess && systemError) {
      toast.error(handleError, {
        onClose: () => {
          handleOnClose(false);
        },
        containerId: id,
        className: style,
      });
    } else if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          handleOnClose(false);
        },
        containerId: id,
        className: style,
      });
    } else if (handleError !== '' && isSuccess) {
      if (datalayer !== undefined) {
        sendEventToDataLayer({
          event: 'form_success',
          type: datalayer,
        });
      }
      toast.success(handleError, {
        onClose: () => {
          handleOnClose(true);
        },
        containerId: id,
        className: style,
      });
    }
  }, [handleError]);

  return (
    handleError !== '' &&
    stateId === id && (
      <div key={`${stateName}_component_${id}`}>
        <div className={styles.overlayToast}></div>
        <ToastContainer
          key={`${stateName}_component_${id}`}
          containerId={id}
          limit={1}
          autoClose={autoClose && isSuccess ? 2000 : autoClose && !isSuccess ? 3000 : autoClose}
          closeOnClick
          theme='light'
        />
      </div>
    )
  );
};
export default Toast;
