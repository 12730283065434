import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputType } from '../../constants/ComponentEnums.jsx';

const Input = ({
  type,
  id,
  className,
  pattern,
  onChange,
  onFocus,
  placeholder,
  value,
  required = false,
  hidden,
  onBlur,
  icon,
  labelValue,
  labelFontType,
  error,
  onKeyPress,
  disabled,
  ref,
  style,
  checked,
  onClick,
  labelOnRight,
  textArea,
  requiredError,
  regexType,
  accept,
}) => {
  let checkIcon = icon !== undefined ? <FontAwesomeIcon icon={icon} className='me-1' /> : null;
  let checkRequiredField = required ? <span className='text-danger fw-bold ms-1'>*</span> : null;
  let errorField = ' border border-danger';
  switch (type) {
    case InputType.Checkbox:
      type = 'checkbox';
      className = `form-check-input border border-2 ${labelOnRight === true ? '' : 'ms-3 '} `;
      break;
    case InputType.Switch:
      type = 'checkbox';
      className = 'form-check-input';
      break;
    case InputType.Radio:
      type = 'radio';
      className = 'form-check-input';
      break;
    default:
      break;
  }
  const input = (
    <React.Fragment>
      {labelValue !== undefined && labelOnRight === undefined && (
        <React.Fragment>
          {checkIcon}
          <label key={`input.label.${id}_${labelValue}`} className={`${labelFontType} form-check-label`}>
            {labelValue}
          </label>
          {checkRequiredField}
        </React.Fragment>
      )}
      {textArea && (
        <React.Fragment>
          <textarea
            key={`input.textarea.${id}_${placeholder}`}
            id={id}
            className={error !== undefined || requiredError !== undefined ? (className += errorField) : className}
            cols={textArea.col}
            rows={textArea.row}
            onChange={onChange}
            onFocus={onFocus}
            required={required}
            placeholder={placeholder}
            onBlur={onBlur}
            value={value}
            name={regexType}
            disabled={disabled}
          />
          <div key={`input.textarea.error.${id}_${placeholder}`} className='text-center'>
            {error || requiredError}
          </div>
        </React.Fragment>
      )}
      {textArea === undefined && (
        <React.Fragment>
          <input
            key={`input.main.${id}_${placeholder}`}
            type={type}
            id={id}
            ref={ref}
            className={error !== undefined || requiredError !== undefined ? (className += errorField) : className}
            placeholder={placeholder}
            value={value}
            checked={checked}
            pattern={pattern}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            required={required}
            disabled={disabled}
            hidden={hidden}
            onKeyPress={onKeyPress}
            style={style}
            name={regexType}
            accept={accept}
          />
          {labelValue !== undefined && labelOnRight !== undefined && (
            <React.Fragment>
              {checkIcon}
              <label
                key={`input.error.label.${id}_${placeholder}`}
                onClick={onClick}
                htmlFor={id}
                className={`${labelFontType} form-check-label ms-lg-2 ms-1`}
              >
                {labelValue}
              </label>
              {checkRequiredField}
            </React.Fragment>
          )}
          <div key={`input.error.${id}_${placeholder}`} className='text-center'>
            {error || requiredError}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
  return input;
};
export default Input;
