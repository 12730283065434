import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import styles from './BasicConfirmModal.module.scss';

import { useTranslation } from 'react-i18next';

import { BasicConfirmModalActions } from '../../constants/Enum.jsx';
import HeadTags from '../HeadTags/HeadTags.jsx';

const BasicConfirmModal = forwardRef(({ onClose, onSave, type }, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleEscKeyPress = (event) => {
    if (event.keyCode === 27 && isOpen) {
      onClose();
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13 && isOpen) {
      onSave();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleEscKeyPress);
    window.addEventListener('keyup', handleEnterKeyPress);
    return () => {
      window.removeEventListener('keyup', handleEscKeyPress);
      window.removeEventListener('keyup', handleEnterKeyPress);
    };
  }, [isOpen]);

  function close() {
    setIsOpen(false);
  }
  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
      onClose();
    },
    open() {
      setIsOpen(true);
    },
  }));

  return (
    isOpen && (
      <div
        key={`BasicConfirmModal.div${ref}`}
        className={styles.basic_confirm_modal_overlay}
        onClick={(e) => e.stopPropagation()}
      >
        <div key={`BasicConfirmModal.div2${ref}`} className={styles.basic_confirm_modal} tabIndex='-1'>
          <div key={`BasicConfirmModal.div3${ref}`} className={styles.basic_confirm_modal_dialog}>
            <div key={`BasicConfirmModal.div4${ref}`} className={styles.modal_content}>
              <div key={`BasicConfirmModal.div5${ref}`} className={styles.basic_confirm_modal_header}>
                <button
                  key={`BasicConfirmModal.button${ref}`}
                  type='button'
                  className={styles.basic_confirm_modal_header}
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                    close();
                  }}
                  aria-label='Close'
                />
              </div>
              <div key={`BasicConfirmModal.div6${ref}`} className={styles.basic_confirm_modal_title}>
                <HeadTags hSize={4} strong text={t(`basicConfirmModal.${type?.title}`)} />
              </div>
              <div key={`BasicConfirmModal.div7${ref}`} className={styles.basic_confirm_modal_body}>
                <p key={`BasicConfirmModal.p${ref}`}>{t(`basicConfirmModal.${type?.text}`)}</p>
              </div>
              <div key={`BasicConfirmModal.div8${ref}`} className={styles.basic_confirm_modal_footer}>
                <button
                  key={`BasicConfirmModal.button2${ref}`}
                  type='button'
                  className={type?.firstButton?.className}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (type === BasicConfirmModalActions.logOut) {
                      onSave();
                      close();
                    } else {
                      onClose();
                      close();
                    }
                  }}
                >
                  {t(`${type?.firstButton?.text}`)}
                </button>
                <button
                  key={`BasicConfirmModal.button3${ref}`}
                  type='button'
                  className={type?.secondButton?.className}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (type === BasicConfirmModalActions.logOut) {
                      if (onClose) {
                        onClose();
                      }
                      close();
                    } else {
                      onSave();
                      close();
                    }
                  }}
                >
                  {t(`${type?.secondButton?.text}`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
export default BasicConfirmModal;
