import React, { useEffect } from 'react';

import styles from './Topbar.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import Actions from '../../store/redux/actions/index.js';

import checkLogged from '../../utils/checkLogged.js';

import { FontAwesomeIcons } from '../../constants/ComponentEnums.jsx';
import { storageKeys, storageValues } from '../../constants/Enum.jsx';
import Icon from '../FontAwesome/Icon.jsx';
import Basket from './Submenus/Basket.jsx';
import Languages from './Submenus/Languages.jsx';
import MyProfile from './Submenus/MyProfile.jsx';
import Notifications from './Submenus/Notifications.jsx';
import Recommend from './Submenus/Recommend.jsx';

const Topbar = ({ handleSideBar }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const userInfo = useSelector((state) => state.user.getById);
  const selfUpdate = useSelector((state) => state.user.selfUpdate);
  const userPackage = useSelector((state) => state.userPackage);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const levelGroup = useSelector((state) => state.preference.levelGroup);
  const banner = useSelector((state) => state.banner.getActiveBannerByZone);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;

  useEffect(() => {
    if (
      auth !== '' &&
      userInfo?.id === undefined &&
      sessionStorage.getItem(storageKeys.landingRule) !== storageValues.landingRule &&
      selfUpdate === ''
    ) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    } else if (
      auth !== '' &&
      userInfo?.id === undefined &&
      sessionStorage.getItem(storageKeys.landingRule) !== storageValues.landingRule &&
      Object.keys(userPackage?.packageData).length > 0 &&
      selfUpdate
    ) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    }
  }, [userInfo?.id, selfUpdate]);

  useEffect(() => {
    if (userInfo?.userArea !== undefined && levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(userInfo?.userArea ?? 0));
    }
  }, [userInfo?.userArea]);

  return (
    <div
      key={'topbar.main.container'}
      className={`${styles.container} ${
        (banner?.pictureWeb || banner?.pictureMobile) && lengthCurrentUserClaim ? styles.specialContainer : ''
      }`}
    >
      <div key={'burger_container'} className={styles.burger_container}>
        <Icon key={'tobar.Icon'} icon={FontAwesomeIcons.faBars} onClick={() => handleSideBar(true)} />
      </div>
      {lengthCurrentUserClaim && (
        <div key={'recommed.div'} className={`${styles.recommed}`}>
          <Recommend key={'Recommend'} />
        </div>
      )}
      <div key={'actions.div'} className={`${styles.actions}`}>
        <div key={'actions2nd.div'} className={`d-flex align-items-center justify-content-evenly me-2`}>
          <Languages key={'Languages'} />
          <Notifications key={'Notifications'} />
          {currentUserClaim?.length !== 0 ? undefined : userInfo?.userArea === 3 ? undefined : (
            <Basket key={'Basket'} />
          )}
          <MyProfile key={'MyProfile'} />
        </div>
      </div>
    </div>
  );
};
export default Topbar;
