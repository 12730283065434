import React, { useEffect } from 'react';

import styles from './Basket.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Actions from '../../../store/redux/actions/index.js';

import findMyPath from '../../../utils/findMyPath.js';

import { FontAwesomeIcons } from '../../../constants/ComponentEnums.jsx';
import Icon from '../../FontAwesome/Icon.jsx';

const Basket = () => {
  const orderItems = useSelector((state) => state.order.basketItems);
  const isSuccess = useSelector((state) => state.order.isSuccess);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendarTable = useSelector((state) => state.order.calendarTable);
  const lengthBasket = orderItems?.unpayedOrderItemList?.length;
  const lengthCalendar = calendarTable.length > 0 ? 1 : undefined;
  const checkBadgeCount = lengthCalendar || lengthBasket;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const basketStatus = useSelector((state) => state.order.isEmpty);
  const landingOrder = useSelector((state) => state.userPackage.isPackageComplete);

  useEffect(() => {
    if (
      !landingOrder &&
      isSuccess &&
      lengthCurrentUserClaim &&
      !basketStatus &&
      calendarTable?.length > 0 &&
      calendarTable?.[0]?.sessionCount === 1
    ) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      !landingOrder &&
      isSuccess &&
      lengthCurrentUserClaim &&
      basketStatus &&
      calendarTable?.length === 0 &&
      orderItems?.unpayedOrderItemList?.length > 0
    ) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
  }, [isSuccess]);

  return (
    <div className={`${styles.iconContainerBasket}`} onClick={() => navigate(findMyPath('order-basket'))}>
      <Icon icon={FontAwesomeIcons.faCartShopping} size={'xl'} />
      {checkBadgeCount && <span className={styles.badgeCustomBasket}>{checkBadgeCount}</span>}
    </div>
  );
};

export default Basket;
