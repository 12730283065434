import React, { useRef, useState } from 'react';

import styles from './Sidebar.module.scss';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router';

import lg1 from '../../assets/images/public/meet2talk-icon.svg';
import lg2 from '../../assets/images/public/meet2talk-logo.svg';

import Actions from '../../store/redux/actions/index.js';

import findMyPath from '../../utils/findMyPath.js';

import checkClaims from '../../hooks/useCheckClaims.js';
import checkGroupClaims from '../../hooks/useCheckGroupClaims.js';
import useClickOutside from '../../hooks/useClickOutside.js';
import generateRandomKey from '../../hooks/useKeyGenerator.js';
import useScreenSize from '../../hooks/useScreenSize.js';

import Feedback from '../../pages/Landing/Feedback.jsx';

import Claims from '../../constants/Claims.js';
import { FontAwesomeBrandIcons, FontAwesomeIcons } from '../../constants/ComponentEnums.jsx';
import { BasicConfirmModalActions } from '../../constants/Enum.jsx';
import Icon from '../FontAwesome/Icon.jsx';

const Modal = React.lazy(() => import('../Modal/Modal.jsx'));
const BasicConfirmModal = React.lazy(() => import('../Modal/BasicConfirmModal.jsx'));

const Sidebar = ({ sidebarIsOpen, handleSideBar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserCompanyId = userInfo?.companyId;
  const [menuOpen, setMenuOpen] = useState({});
  const [feedbackOn, setFeedbackOn] = useState(false);
  const confirmModalRef = useRef();
  const isM2TManager =
    (checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) ||
      checkClaims(currentUserClaim, Claims.actionClaims.agency)) &&
    currentUserCompanyId === 1;
  const { isMobile, isTablet } = useScreenSize();

  let domNode = useClickOutside(() => {
    if (sidebarIsOpen && (isMobile || isTablet)) {
      handleSideBar(!sidebarIsOpen);
    }
  });

  function handleMenus(id) {
    setMenuOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }

  const signOut = () => {
    dispatch(Actions.authActions.logoutAction(navigate));
  };

  const NavUrl = ({ url, icon, description, target, func, img, style, menu }) => {
    const isMenuOpen = menu && menuOpen?.[menu] === true && func !== undefined;
    const isMenuClosed = menuOpen?.[menu] && !sidebarIsOpen;

    return (
      <li key={generateRandomKey()} className={`${styles.li_navlink} ${isMenuOpen ? styles.menuOpen : ''}`}>
        <NavLink
          key={generateRandomKey()}
          to={url || '#'}
          className={({ isActive }) => (isActive && func === undefined ? styles.active : undefined)}
          target={target}
          onClick={(e) => {
            if (func !== undefined) {
              e.preventDefault();
              func();
            } else {
              if (isMobile || isTablet) {
                handleSideBar(false);
              }
            }
          }}
        >
          {img && <img src={img} alt={generateRandomKey()} loading='lazy' />}
          {icon && <Icon key={generateRandomKey()} icon={icon} style={style} />}
          <span
            key={generateRandomKey()}
            className={`${
              style === undefined && (menu === undefined || func !== undefined)
                ? styles.description
                : style !== undefined && menu !== undefined
                  ? styles.customDescription
                  : ''
            } ${isMenuClosed ? styles.menuClosedcustomDescription : styles.menuOpencustomDescription}`}
          >
            {description}
          </span>
        </NavLink>
      </li>
    );
  };

  const UIContent = (
    <React.Fragment>
      {(userInfo?.companyId !== 1 || userInfo?.userArea !== 3) && (
        <React.Fragment>
          {userInfo?.userArea !== 3 && (
            <NavUrl
              key={'UIContent.mentors'}
              url={findMyPath('speakers')}
              icon={FontAwesomeIcons.faPeopleGroup}
              description={t('sidebar.mentors')}
            />
          )}
          <NavUrl
            key={'UIContent.schedule'}
            url={findMyPath('schedule')}
            icon={FontAwesomeIcons.faCalendarDays}
            description={t('sidebar.schedule')}
          />
          <NavUrl
            key={'UIContent.sessions'}
            url={findMyPath('sessions')}
            icon={FontAwesomeIcons.faChalkboardTeacher}
            description={t('sidebar.sessions')}
          />
          {userInfo?.userArea !== 3 && (
            <NavUrl
              key={'UIContent.subscription'}
              url={findMyPath('order/package')}
              icon={FontAwesomeIcons.faCalendarCheck}
              description={t('sidebar.subscription')}
            />
          )}
          <NavUrl
            key={'UIContent.orders'}
            url={findMyPath('orders')}
            icon={FontAwesomeIcons.faListOl}
            description={t('sidebar.orders')}
          />
          {userInfo?.userArea !== 3 && (
            <NavUrl
              key={'UIContent.gift_code'}
              url={'/gift-code'}
              icon={FontAwesomeIcons.faGifts}
              description={t('headerMyProfile.gift_code')}
            />
          )}
        </React.Fragment>
      )}
      <NavUrl
        key={'UIContent.blog'}
        url={'https://blog.meet2talk.online/'}
        icon={FontAwesomeIcons.faFilePen}
        description={t('sidebar.blog')}
        target={'_blank'}
      />
      <NavUrl
        key={'UIContent.feedback'}
        icon={FontAwesomeIcons.faCommenting}
        description={t('feedback')}
        func={() => {
          if (isMobile || isTablet) {
            handleSideBar(!sidebarIsOpen);
          }
          setFeedbackOn(true);
        }}
      />
      <NavUrl
        key={'UIContent.tiktok'}
        url={findMyPath('tiktok')}
        icon={FontAwesomeBrandIcons.faTiktok}
        description={t('sidebar.tiktok')}
      />
      <NavUrl
        key={'UIContent.support'}
        url='/support'
        icon={FontAwesomeIcons.faHeadphones}
        description={t('sidebar.support')}
      />
    </React.Fragment>
  );
  const SpeakerContent = (
    <React.Fragment>
      <NavUrl
        url={'/speaker-update'}
        icon={FontAwesomeIcons.faUserEdit}
        description={t('sidebar.speaker_information')}
      />
      <NavUrl
        url={'/availability-calendar'}
        icon={FontAwesomeIcons.faCalendarDays}
        description={t('sidebar.availability_calendar')}
      />
      <NavUrl url='/backoofice-chatroom' icon={FontAwesomeIcons.faChalkboardTeacher} description={t('Sessions Chat')} />
      <NavUrl
        url='/meeting-schedule'
        icon={FontAwesomeIcons.faCalendarCheck}
        description={t('sidebar.active_meeting_schemas')}
      />
      <NavUrl url='/attendee-list' icon={FontAwesomeIcons.faPeopleGroup} description={t('sidebar.attendees')} />
      {checkClaims(currentUserClaim, Claims.backofficeClaims.speaker.effort) && (
        <NavUrl
          url='/speaker-effort-report'
          icon={FontAwesomeIcons.faChartLine}
          description={t('sidebar.effort_report')}
        />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.career.getAll) && (
        <NavUrl url='/career-list' icon={FontAwesomeIcons.faNetworkWired} description={t('sidebar.career')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.career.getAll) && (
        <NavUrl url='/speaker-list' icon={FontAwesomeIcons.faPeopleGroup} description={t('sidebar.speakers')} />
      )}
    </React.Fragment>
  );
  const BOContent = (
    <React.Fragment>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.speaker.get) && (
        <NavUrl url='/speaker-list' icon={FontAwesomeIcons.faPeopleGroup} description={t('sidebar.speakers')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.attendee.getAll) && (
        <NavUrl url='/attendee-list' icon={FontAwesomeIcons.faUsers} description={t('sidebar.attendees')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.manager.get) && (
        <NavUrl url='/manager-list' icon={FontAwesomeIcons.faUsersGear} description={t('sidebar.managers')} />
      )}
      <h5 className='d-flex align-items-center my-2 ms-2'>
        {!sidebarIsOpen ? <Icon icon={FontAwesomeIcons.faEllipsis} /> : <strong>{t('sidebar.operation')}</strong>}
      </h5>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.calendar.get) && (
        <NavUrl
          url='/meeting-schedule'
          icon={FontAwesomeIcons.faCalendarCheck}
          description={t('sidebar.active_meeting_schemas')}
        />
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.manager) && (
        <NavUrl url='/operations' icon={FontAwesomeIcons.faHeartPulse} description={t('sidebar.operations')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.calendar.get) && (
        <NavUrl
          url='/monthly-schedule'
          icon={FontAwesomeIcons.faCalendarWeek}
          description={t('sidebar.speaker_schedule')}
        />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.meetingSchema.get) && (
        <NavUrl
          url='/meeting-schema-list'
          icon={FontAwesomeIcons.faCalendarWeek}
          description={t('meetingSchema.meeting_schema')}
        />
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.order) && (
        <NavUrl
          icon={FontAwesomeIcons.faTableList}
          description={t('order.order')}
          func={() => handleMenus('orderMenu')}
          menu={'orderMenu'}
        />
      )}
      <div className={`collapse ${menuOpen.orderMenu ? 'show' : ''}`} id='orders'>
        <div className={`${styles.minusItem} ${menuOpen.orderMenu && !sidebarIsOpen ? styles.menuClosed : ''}`}>
          <NavUrl
            url='/order-list'
            icon={FontAwesomeIcons.faMinus}
            style={styles.customFont}
            description={t('order.list')}
            menu={'orderMenu'}
          />
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.accountingDept) && (
            <NavUrl
              url='/order-payments'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.payments')}
              menu={'orderMenu'}
            />
          )}
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.accountingDept) && (
            <NavUrl
              url='/eft-payments'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.eft_control')}
              menu={'orderMenu'}
            />
          )}
          {checkClaims(currentUserClaim, Claims.actionClaims.orderReports) && (
            <NavUrl
              url='/remaining-order-calculation'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.remaining_order_calculation')}
              menu={'orderMenu'}
            />
          )}
        </div>
      </div>
      {checkGroupClaims(currentUserClaim, Claims.headClaims.order) && (
        <NavUrl
          icon={FontAwesomeBrandIcons.faJediOrder}
          description={t('trials')}
          func={() => handleMenus('trialsMenu')}
          menu={'trialsMenu'}
        />
      )}
      <div className={`collapse ${menuOpen.trialsMenu ? 'show' : ''}`} id='trials'>
        <div className={`${styles.minusItem} ${menuOpen.trialsMenu && !sidebarIsOpen ? styles.menuClosed : ''}`}>
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) && (
            <NavUrl
              url='/created-today-trials'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('order.todaysTrials')}
              menu={'trialsMenu'}
            />
          )}
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) && (
            <NavUrl
              url='/upcoming-trials'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('order.upcomingTrials')}
              menu={'trialsMenu'}
            />
          )}
        </div>
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.company.getAll) && (
        <NavUrl url='/company-list' icon={FontAwesomeIcons.faPenToSquare} description={t('company.companies')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.branchFreeze.get) && (
        <NavUrl
          url='/branch-holiday-list'
          icon={FontAwesomeIcons.faPlaneDeparture}
          description={t('sidebar.branchFreeze')}
        />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.career.get) && (
        <NavUrl url='/career-list' icon={FontAwesomeIcons.faFilePdf} description={t('sidebar.career')} />
      )}
      {checkClaims(currentUserClaim, 'speaker.effort') && (
        <NavUrl
          url='/speaker-effort-report'
          icon={FontAwesomeIcons.faRankingStar}
          description={t('sidebar.effort_report')}
        />
      )}
      {(checkClaims(currentUserClaim, Claims.backofficeClaims.banner.getAll) ||
        checkClaims(currentUserClaim, Claims.backofficeClaims.campaign.getAll) ||
        checkClaims(currentUserClaim, Claims.backofficeClaims.couponCodes.getAll)) && (
        <NavUrl
          icon={FontAwesomeIcons.faTags}
          description={t('sidebar.campaign')}
          func={() => handleMenus('campaignMenu')}
          menu={'campaignMenu'}
        />
      )}
      <div className={`collapse ${menuOpen.campaignMenu ? 'show' : ''}`} id='campaigns'>
        <div className={`${styles.minusItem} ${menuOpen.campaignMenu && !sidebarIsOpen ? styles.menuClosed : ''}`}>
          {checkClaims(currentUserClaim, Claims.backofficeClaims.banner.getAll) && (
            <NavUrl
              url='/banner-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.banner')}
              menu={'campaignMenu'}
            />
          )}
          {checkClaims(currentUserClaim, Claims.backofficeClaims.campaign.getAll) && (
            <NavUrl
              url='/campaign-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.campaign')}
              menu={'campaignMenu'}
            />
          )}
          {checkClaims(currentUserClaim, Claims.backofficeClaims.couponCodes.getAll) && (
            <NavUrl
              url='/coupon-code-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.coupon_codes')}
              menu={'campaignMenu'}
            />
          )}
        </div>
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.mailSubscription.getAll) && (
        <NavUrl url='/call-them' icon={FontAwesomeIcons.faPhoneVolume} description={t('sidebar.call_them')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.companyForm.getAll) && (
        <NavUrl url='/company-form-list' icon={FontAwesomeIcons.faUserTie} description={t('companyForm.list')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.mailSubscription.getAll) && (
        <NavUrl url='/lead-list' icon={FontAwesomeIcons.faArrowsDownToPeople} description={t('lead.list')} />
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.feedback.getAll) && (
        <NavUrl url='/feedback-list' icon={FontAwesomeIcons.faCommentDots} description={t('sidebar.feedback')} />
      )}
      {checkClaims(currentUserClaim, Claims.actionClaims.packageReporting) && (
        <NavUrl url='/reporting-list' icon={FontAwesomeIcons.faPenToSquare} description={t('sidebar.reporting')} />
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.ageInterval) && (
        <NavUrl
          icon={FontAwesomeIcons.faPenToSquare}
          description={t('sidebar.preferences')}
          func={() => handleMenus('preferenceMenu')}
          menu={'preferenceMenu'}
        />
      )}
      <div className={`collapse ${menuOpen.preferenceMenu ? 'show' : ''}`} id='preference'>
        <div className={`${styles.minusItem} ${menuOpen.preferenceMenu && !sidebarIsOpen ? styles.menuClosed : ''}`}>
          {checkGroupClaims(currentUserClaim, Claims.headClaims.ageInterval) && (
            <NavUrl
              url='/age-interval-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.age_interval_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.location) && (
            <NavUrl
              url='/location-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.location_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.sessionCategory) && (
            <NavUrl
              url='/session-category-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.session_category_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.sessionPackage) && (
            <NavUrl
              url='/session-package-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.session_package_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.timesPerMonth) && (
            <NavUrl
              url='/times-per-month-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.times_per_month_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.timesPerWeek) && (
            <NavUrl
              url='/times-per-week-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.times_per_week_list')}
              menu={'preferenceMenu'}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.zone) && (
            <NavUrl
              url='/zone-list'
              icon={FontAwesomeIcons.faMinus}
              style={styles.customFont}
              description={t('sidebar.preferences.zone_list')}
              menu={'preferenceMenu'}
            />
          )}
        </div>
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.package.create) && (
        <div className='mt-3'>
          <NavUrl url='/test-price' icon={FontAwesomeIcons.faGear} description={'Price Test'} />
        </div>
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.package.create) && (
        <div className='mt-3'>
          <NavUrl url='/test-package' icon={FontAwesomeIcons.faGear} description={'Package Test'} />
        </div>
      )}
    </React.Fragment>
  );
  const CompanyContent = (
    <React.Fragment>
      <NavUrl url='/attendee-list' icon={FontAwesomeIcons.faUserFriends} description={t('sidebar.attendees')} />
      <NavUrl
        url='/meeting-schedule'
        icon={FontAwesomeIcons.faCalendarWeek}
        description={t('sidebar.active_meeting_schemas')}
      />
    </React.Fragment>
  );

  const attendee = currentUserClaim?.length === 0;
  const speaker =
    checkClaims(currentUserClaim, 'speakerAvailability.create') && !checkClaims(currentUserClaim, 'order.get');

  const content = (
    <div>
      <div className={`${styles.sidebar_container}`}>
        <nav ref={domNode} className={sidebarIsOpen ? '' : styles.nav_small}>
          <Link className={`mt-2 ${styles.logo}`} to={findMyPath('dashboard')}>
            {sidebarIsOpen ? (
              <img className={styles.imgLogo} src={lg2} alt='Meet2Talk Logo' width='100%' loading='lazy' />
            ) : (
              <img className={styles.imgLogo} src={lg1} alt='Alternate Logo' width='100%' loading='lazy' />
            )}
          </Link>
          <ul className='mt-4'>
            <NavUrl
              key={'dashboard'}
              url={findMyPath('dashboard')}
              description={t('sidebar.dashboard')}
              icon={FontAwesomeIcons.faGaugeHigh}
            />
            {attendee ? UIContent : speaker ? SpeakerContent : isM2TManager ? BOContent : CompanyContent}
            <li
              key={'logout'}
              role={'button'}
              className={styles.li_navlink}
              onClick={() => {
                if (sidebarIsOpen && window.innerWidth < 768) {
                  handleSideBar(false);
                }
                confirmModalRef.current.open();
              }}
            >
              <a key={'a.logout'} id='logOut' href='#/' role='button' className='btn btn-default text-start'>
                <Icon key={'icon.logout'} icon={FontAwesomeIcons.faSignOut} />
                <label key={'label.logout'} className={styles.description} role={'button'}>
                  {t('sidebar.logout')}
                </label>
              </a>
            </li>
          </ul>
          <div className={styles.menuArrow}>
            <Icon
              me={1}
              ms={1}
              size={'2x'}
              icon={sidebarIsOpen ? FontAwesomeIcons.faChevronLeft : FontAwesomeIcons.faChevronRight}
              onClick={() => {
                handleSideBar(!sidebarIsOpen);
              }}
            />
          </div>
        </nav>
      </div>
      <BasicConfirmModal
        key={'are_you_sure'}
        ref={confirmModalRef}
        title={t('sidebar.are_you_sure')}
        text={t('sidebar.are_you_sure')}
        onSave={() => signOut()}
        type={BasicConfirmModalActions.logOut}
      />
      {feedbackOn && (
        <Modal
          key={'feedback.form_header'}
          isVisible={feedbackOn}
          title={t('feedback.form_header')}
          titleMS={2}
          styleProps={{ maxWidth: 450, borderRadius: 15 }}
          content={<Feedback handleModal={(val) => setFeedbackOn(val)} />}
          onClose={() => setFeedbackOn(false)}
        />
      )}
    </div>
  );

  return content;
};

export default Sidebar;
