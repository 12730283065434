import React, { useEffect, useRef, useState } from 'react';

import styles from './MyProfile.module.scss';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import cafe from '../../../assets/images/cafe.svg';
import kids from '../../../assets/images/kids.svg';
import company from '../../../assets/images/marketing.svg';
import online from '../../../assets/images/meet2talk_button.svg';

import Actions from '../../../store/redux/actions/index.js';

import useClickOutside from '../../../hooks/useClickOutside.js';
import useScreenSize from '../../../hooks/useScreenSize.js';

import { BackGroundColors, FontAwesomeIcons, TextColors } from '../../../constants/ComponentEnums.jsx';
import { BasicConfirmModalActions } from '../../../constants/Enum.jsx';
import ButtonIconText from '../../Button/ButtonIconText.jsx';
import Dropdown from '../../Dropdown/Dropdown.jsx';
import Icon from '../../FontAwesome/Icon.jsx';
import HeadTags from '../../HeadTags/HeadTags.jsx';
import BasicConfirmModal from '../../Modal/BasicConfirmModal.jsx';

const MyProfile = () => {
  const confirmModalRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userArea = useSelector((state) => state.staticData.UserArea);
  const userLang = useSelector((state) => state.lang.language);
  const basketStatus = useSelector((state) => state.order.isEmpty);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const landingOrder = useSelector((state) => state.userPackage.isPackageComplete);
  const [handleArea, setHandleArea] = useState(null);
  const { isMobile } = useScreenSize();

  const handleConfirmation = (confirm) => {
    if (confirm) {
      signOut();
    }
  };

  const signOut = () => {
    dispatch(Actions.authActions.logoutAction(navigate));
  };

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  let domNode = useClickOutside(() => {
    setIsProfileOpen(false);
  });

  const [changeMenu, setChangeMenu] = useState({});

  const handleSelectChange = async (target) => {
    if (userInfo?.userArea !== parseInt(target.value)) {
      setUserAreaDetails(parseInt(target.value));
      await dispatch(Actions.onboardActions.onboardType(parseInt(target.value))).then(() => {
        dispatch(Actions.orderActions.selectSessionReducer([], ''));
        dispatch(Actions.filterActions.resetStateAction());
      });
      const myPath = target.lang;
      await dispatch(Actions.userActions.selfUpdateAction({ userArea: parseInt(target.value) }, 'UserArea')).then(
        () => {
          setTimeout(() => {
            navigate(`${myPath}/dashboard`);
          }, 100);
        }
      );
    }
  };

  const setUserAreaDetails = (userArea) => {
    switch (userArea) {
      case 0:
        setHandleArea({
          title: t('UserArea.0'),
          backGroundColor: BackGroundColors.DashboardOnline,
          svg: online,
        });
        break;
      case 1:
        setHandleArea({
          title: t('UserArea.1'),
          backGroundColor: BackGroundColors.DashboardCafe,
          svg: cafe,
        });
        break;
      case 2:
        setHandleArea({
          title: t('UserArea.2'),
          backGroundColor: BackGroundColors.DashboardKids,
          svg: kids,
        });
        break;
      case 3:
        setHandleArea({
          title: t('UserArea.3'),
          backGroundColor: BackGroundColors.DashboardCompany,
          svg: company,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (handleArea === null) {
      setUserAreaDetails(userInfo?.userArea);
    }
  }, [userInfo?.userArea, userLang]);

  useEffect(() => {
    if (userInfo?.id !== undefined && basketStatus === '' && lengthCurrentUserClaim && !landingOrder) {
      dispatch(Actions.orderActions.getBasketItemsAction(userInfo?.userArea));
    }
  }, [userInfo]);

  const handleMenuHover = (e) => {
    setChangeMenu({ [e.target.id]: true });
  };
  const handleMenuHoverMobile = (e, to = undefined) => {
    setChangeMenu({ [e.target.id]: true });
    if (isMobile) {
      if (to !== undefined) {
        setIsProfileOpen(false);
        navigate(to);
      }
    }
  };
  const handleSelectMenu = () => {
    setIsProfileOpen(false);
  };

  return (
    <div key={'myProfile.main'} className={styles.avatar_container} role='button'>
      <BasicConfirmModal
        key={'myProfile.are_you_sure'}
        ref={confirmModalRef}
        title={t('sidebar.are_you_sure')}
        text={t('sidebar.are_you_sure')}
        onClose={(e) => {
          handleConfirmation(false);
          setIsProfileOpen(false);
        }}
        onSave={() => handleConfirmation(true)}
        type={BasicConfirmModalActions.logOut}
      />
      <div
        ref={domNode}
        className={styles.icon_avatar_container}
        onClick={() => {
          setIsProfileOpen(!isProfileOpen);
          setChangeMenu({});
        }}
      >
        {userInfo?.userImage ? (
          <img
            key={userInfo?.userImage}
            className={'rounded-circle me-3'}
            src={userInfo?.userImage}
            alt={userInfo?.userImage}
            style={{ objectFit: 'cover' }}
            width={32}
            height={32}
            loading='lazy'
          />
        ) : (
          <Icon me={1} size={'2xl'} icon={FontAwesomeIcons.faCircleUser} />
        )}
      </div>
      <div className='mt-1 mb-1'>
        {lengthCurrentUserClaim && (
          <Dropdown
            key={'myProfile.Onboard_Type'}
            name={'Onboard_Type'}
            options={userArea}
            id={'Onboard_Type'}
            title={handleArea?.title}
            rounded={2}
            backGroundAll={handleArea?.backGroundColor}
            propNames={{
              key: 'name',
              value: 'value',
              name: 'key',
              path: 'path',
            }}
            handleChange={(target) => handleSelectChange(target)}
            svgWidth={isMobile ? 25 : 40}
            svg={handleArea?.svg}
            textColor={TextColors.Light}
            rightArrowUp
          />
        )}
      </div>
      <div ref={domNode} className={`${styles.menu} ${isProfileOpen ? styles.menu_active : ''}`}>
        <div className='d-flex align-items-center'>
          <div className='flex-shrink-0'>
            <div className={styles.icon_avatar_container}>
              {userInfo?.userImage ? (
                <img
                  key={`${userInfo?.userImage}indiv`}
                  className={'rounded-circle'}
                  src={userInfo?.userImage}
                  alt={userInfo?.userImage}
                  style={{ objectFit: 'cover' }}
                  width={34}
                  height={34}
                  loading='lazy'
                />
              ) : (
                <Icon key={'FontAwesomeIcons.faCircleUser'} mt={2} size={'2xl'} icon={FontAwesomeIcons.faCircleUser} />
              )}
            </div>
          </div>
          <div className='flex-grow-1 ms-3'>
            <div>
              <HeadTags hSize={7} text={userInfo?.firstName + '  ' + userInfo?.lastName} textNowrap strong />
            </div>
            <div>
              <HeadTags hSize={7} text={userInfo?.phone} strong />
            </div>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-1'></div>
          <div className='col border-bottom border-1'></div>
          <div className='col-1'></div>
        </div>
        <div className='container' style={{ zIndex: '1000' }}>
          <div className={'row mt-3 text-muted'}>
            {changeMenu.personalInformation === undefined ? (
              <div
                id={'personalInformation'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/personal-information')}
              >
                <Icon style={styles.subMenuIcon} icon={FontAwesomeIcons.faUser} />
                <label
                  id={'personalInformation'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/personal-information')}
                  className={styles.subMenuText}
                >
                  {t('headerMyProfile.personal_information')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={FontAwesomeIcons.faUser}
                rounded={2}
                title={t('headerMyProfile.personal_information')}
                to={'profile/personal-information'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {!lengthCurrentUserClaim ? undefined : changeMenu.settings === undefined ? (
              <div
                id={'settings'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/settings')}
                className='mt-2'
              >
                <Icon style={styles.subMenuIcon} icon={FontAwesomeIcons.faCogs} />
                <label
                  id={'settings'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/settings')}
                  className={styles.subMenuText}
                >
                  {t('headerMyProfile.settings')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={FontAwesomeIcons.faCogs}
                rounded={2}
                title={t('headerMyProfile.settings')}
                to={'profile/settings'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {changeMenu.changePassword === undefined ? (
              <div
                id={'changePassword'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/change-password')}
                className='mt-2'
              >
                <Icon style={styles.subMenuIcon} icon={FontAwesomeIcons.faExchange} />
                <label
                  id={'changePassword'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => handleMenuHoverMobile(e, 'profile/change-password')}
                  className={styles.subMenuText}
                >
                  {t('headerMyProfile.change_password')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={FontAwesomeIcons.faExchange}
                rounded={2}
                title={t('headerMyProfile.change_password')}
                to={'profile/change-password'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {changeMenu.logOut === undefined ? (
              <div
                id={'logOut'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => {
                  e.stopPropagation();
                  confirmModalRef.current.open();
                }}
                className='mt-2'
              >
                <Icon style={styles.subMenuIcon} icon={FontAwesomeIcons.faSignOut} />
                <label
                  id={'logOut'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => {
                    e.stopPropagation();
                    confirmModalRef.current.open();
                  }}
                  className={styles.subMenuText}
                >
                  {t('headerMyProfile.log_out')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={FontAwesomeIcons.faSignOut}
                rounded={2}
                title={t('headerMyProfile.log_out')}
                onClick={() => confirmModalRef.current.open()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
