import React, { useEffect, useState } from 'react';

import styles from './Languages.module.scss';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import gbFlag from '../../../assets/images/flags/gb.svg';
import trFlag from '../../../assets/images/flags/tr.svg';

import Actions from '../../../store/redux/actions/index.js';

import useClickOutside from '../../../hooks/useClickOutside.js';

import { FontAwesomeIcons, TextColors } from '../../../constants/ComponentEnums.jsx';
import Dropdown from '../../Dropdown/Dropdown.jsx';
import Icon from '../../FontAwesome/Icon.jsx';

const Languages = () => {
  const [isLanguagesOpen, setisLanguagesOpen] = useState(false);
  let domNode = useClickOutside(() => {
    setisLanguagesOpen(false);
  });
  const currentLang = useSelector((state) => state.lang.language);
  const flag = useSelector((state) => state.lang.flag);
  const flagSrc = flag === 'gb' ? gbFlag : trFlag;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const languages = [
    { key: 1, name: 'en-GB', value: 0, title: 'English' },
    { key: 2, name: 'tr-TR', value: 1, title: 'Türkçe' },
  ];
  const currencyOptions = useSelector((state) => state.preference.currenyOptions);
  const currency = useSelector((state) => state.preference.currencyUnit);

  const lng = languages
    .find((a) => a.name === currentLang.split(',')[0])
    .name.split('-')
    .at(0)
    .toLowerCase();
  function handleLanguage(target) {
    i18n.changeLanguage(target.title);
    const language = target.title.split('-')[0];
    if (lng !== language) {
      dispatch(Actions.userActions.selfUpdateAction({ language: language, currency: currency.name }, 'Language'));
      dispatch(Actions.langActions.pickLanguage(target.title));
    }
  }

  function handleCurrency(currencyUnit) {
    const currencyName = currencyUnit.Name;
    if (currency?.name !== currencyName) {
      dispatch(Actions.userActions.selfUpdateAction({ language: lng, currency: currencyName }, 'Currency'));
      dispatch(Actions.preferenceActions.setCurrencyAction(currencyUnit, true));
    }
  }

  useEffect(() => {
    if (currencyOptions.length === 0) {
      dispatch(Actions.preferenceActions.getCurrencyAction());
    }
  }, []);

  return (
    <div
      ref={domNode}
      className={`${styles.icon_container} ${styles.Languages} ${isLanguagesOpen ? styles.icon_active : undefined}`}
      onClick={() => {
        setisLanguagesOpen(!isLanguagesOpen);
      }}
    >
      <button
        className='btn btn-default border-0 dropdown-toggle'
        type='button'
        id='navbarDropdown'
        data-bs-toggle='dropdown'
        aria-expanded='false'
        data-bs-auto-close='outside'
      >
        <img className='me-2' src={flagSrc} width={'16px'} alt={'svg'} loading='lazy' />
        {currency.symbol}
        {/* {currentLang?.split(',')[1]} , {currency.Name} */}
      </button>
      <ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
        <div className='p-2'>
          <h6> {t('siteLanguage')}</h6>
          <Dropdown
            options={languages}
            name={'Language'}
            title={currentLang?.split(',')[1]}
            handleChange={(target) => handleLanguage(target)}
            // backGroundAll={BackGroundColors.Grey}
            propNames={{
              id: 'key',
              key: 'name',
              value: 'value',
              name: 'title',
              title: 'name',
            }}
            svg={flagSrc}
            svgRounded={1}
            svgWidth={'15px'}
            rightIcon={FontAwesomeIcons.faChevronDown}
            id={'language'}
            textColor={TextColors.Dark}
            rounded={2}
            bordered
            buttonCol={'col-sm-1 me-sm-2'}
            forCalendar
            forLang
          />
        </div>

        <div className='p-2'>
          <h6> {t('currency')}</h6>

          <div className='dropdown '>
            <button
              className='btn btn-default d-flex align-items-center  w-100 mx-auto justify-content-center border border-1 border-success rounded-2 '
              type='button'
              id='currencyDropdown'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {currency?.name}
              <span className='ms-1'>
                <Icon icon={FontAwesomeIcons.faChevronDown} />
              </span>
            </button>
            <ul className='dropdown-menu' aria-labelledby='currencyDropdown'>
              {currencyOptions.map((currency) => (
                <li key={currency.Code} className='dropdown-item' onClick={() => handleCurrency(currency)}>
                  {currency.Name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ul>
    </div>
  );
};
export default Languages;
