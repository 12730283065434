//ICONS , STYLES
// import { FiAlertTriangle, FiBell, FiDroplet } from "react-icons/fi";
//HOOKS
import React, { useState } from 'react';

import styles from './Notifications.module.scss';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router';

import { faBell, faInbox, faPaperclip, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import useClickOutside from '../../../hooks/useClickOutside.js';

const Notifications = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isNotificationsOpen, setisNotificationsOpen] = useState(false);
  let domNode = useClickOutside(() => {
    setisNotificationsOpen(false);
  });

  const [notifications, setNotifications] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get('http://localhost:4000/notificationsData') //json web server test data
  //     .then((response) => setNotifications(response?.data))
  //     .catch((error) => {
  //       // Handle error
  //       console.error('Axios error:', error);
  //     });
  // }, []);

  const notifLength = notifications.filter((item) => item.isRead === false).length;

  const handleRead = (id) => {
    const newNotif = notifications?.map((item) => {
      if (item.id === id) {
        return { ...item, isRead: true };
      } else {
        return item;
      }
    });
    setNotifications(newNotif);
  };

  const allRead = () => {
    setNotifications((prev) =>
      prev.map((item) => {
        return { ...item, isRead: true };
      })
    );
  };

  const activityClass = (type) => {
    switch (type) {
      case 'Online':
        return 'border-custom text-custom fw-bold bg-custom bg-opacity-25  ';
      case 'Kids':
        return 'border-t-turuncu text-t-turuncu bg-t-turuncu bg-opacity-25 ';
      case 'Cafe':
        return 'border-t-sarı text-t-sarı fw-bold bg-t-sarı bg-opacity-25  ';
      case 'Company':
        return 'border-t-calendar text-t-calendar bg-t-calendar bg-opacity-25 ';
      default:
        return 'border-custom text-custom fw-bold bg-custom bg-opacity-25  ';
    }
  };

  const handleNotificationClick = (el) => {
    localStorage.setItem('topBarData', JSON.stringify(el));
    navigate('/all-notifications', {
      state: { fromNavigateWhere: 'notification' },
    });
  };

  return (
    <div
      ref={domNode}
      className={`${styles.icon_container} ${styles.notifications}  `}
      onClick={() => {
        setisNotificationsOpen(!isNotificationsOpen);
      }}
    >
      {<FontAwesomeIcon className='fa-xl' icon={faBell}></FontAwesomeIcon>}

      {notifLength !== 0 ? <span className='badge badge-danger badge-counter'>&#x2022;</span> : undefined}
      {/* <FiBell /> */}
      {/* INBOX SUBMENU */}
      <div className={`${styles.submenu} ${isNotificationsOpen ? styles.submenu_active : ''}`}>
        <div>
          <div className='row d-flex justify-content-between align-items-center  mb-2 py-1 text-dark'>
            <div className='col d-flex align-items-center'>
              <h6 className='col d-flex align-items-center align-self-center'>
                {t('headerNotification.all_notifications')}
              </h6>
              <span className='badge badge-danger  align-self-start  ' style={{ fontSize: '0.7em' }}>
                {notifLength}
              </span>
            </div>

            <button
              style={{ fontSize: '0.9em' }}
              className='col d-flex justify-content-end   btn btn-link text-decoration-none'
              onClick={allRead}
            >
              {t('headerNotification.mark_all_as_read')}
            </button>
          </div>

          {notifications?.length === 0 ? (
            <div className='h6 text-center my-5 '>{t('headerNotification.no_notifications')}</div>
          ) : (
            <div>
              {notifications?.slice(0, 5).map((el, i) => (
                <div
                  key={i}
                  className='cursor-pointer'
                  onClick={() => {
                    handleRead(el.id);
                    handleNotificationClick(el);
                  }}
                  style={{ fontSize: '0.7em' }}
                >
                  <div className={`d-flex col m-2 p-2 ${el.isRead ? '' : 'bg-lightcustom'}`}>
                    {el.type === 'Success' ? (
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        className='bg-custom'
                        style={{
                          height: '20px',
                          width: '20px',
                          borderRadius: '20%',
                        }}
                      ></FontAwesomeIcon>
                    ) : el.type === 'Info' ? (
                      <FontAwesomeIcon
                        icon={faInbox}
                        className='bg-info'
                        style={{
                          height: '20px',
                          width: '20px',
                          borderRadius: '20%',
                        }}
                      ></FontAwesomeIcon>
                    ) : (
                      <img
                        src={el.avatar}
                        alt={el.name}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '20%',
                        }}
                        loading='lazy'
                      />
                    )}
                    <div className='d-flex'>
                      <div className='d-flex '>
                        <div className='font-bold ms-3 '>
                          <div className='d-flex justify-content-between align-items-center'>
                            <h6 className='col align-self-center  fw-bold' style={{ fontSize: '1em' }}>
                              {el.name}
                            </h6>
                            <span className=' align-self-center text-muted position-absolute end-0 me-4 mt-0 pt-0'>
                              {el.date}
                            </span>
                          </div>

                          <span className=' text-muted d-flex'>{el.action}</span>
                          {el.attachment && (
                            <p className='d-flex align-items-center p-2 rounded-3 bg-light border '>
                              {<FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>} {el.attachment}
                            </p>
                          )}
                          {el.service &&
                            (Array?.isArray(el.service) ? (
                              el?.service.map((type) => (
                                <span
                                  key={type.id}
                                  className={`${activityClass(type.type)} badge border fw-bold rounded-3 d-inline m-1`}
                                >
                                  {type.type}
                                </span>
                              ))
                            ) : (
                              <span
                                className={`${activityClass(el.service)} badge border  fw-bold rounded-3 d-inline m-1`}
                              >
                                {el.service}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                    {el.isComment && <img src={el.comment} alt='a comment' className='ml-auto h-12' loading='lazy' />}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Link className='text-decoration-none text-link' to='/all-notifications'>
          {t('headerNotification.view_all')}
        </Link>
      </div>
    </div>
  );
};

export default Notifications;
