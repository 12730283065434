import React from 'react';

import styles from './HeadTags.module.scss';

export default function HeadTags({
  hSize,
  strong,
  marginLeft,
  textColor,
  text,
  marginBottom,
  marginTop,
  title,
  textCenter,
  textNowrap,
}) {
  let myHeaderTag = undefined;
  let setMyText = strong ? <strong>{text}</strong> : text;
  let className = [
    title ? styles.titleBorder : '',
    marginTop ? `mt-${marginTop}` : '',
    marginLeft ? `ms-${marginLeft}` : '',
    marginBottom ? `mb-${marginBottom}` : '',
    textColor ? styles[textColor] : '',
    textCenter ? 'text-center' : '',
    textNowrap ? 'text-nowrap' : '',
  ];
  className = className.filter(Boolean).join(' ').trim();

  switch (hSize) {
    case 1:
      myHeaderTag = (
        <h1 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h1>
      );
      break;
    case 2:
      myHeaderTag = (
        <h2 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h2>
      );
      break;
    case 3:
      myHeaderTag = (
        <h3 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h3>
      );
      break;
    case 4:
      myHeaderTag = (
        <h4 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h4>
      );
      break;
    case 5:
      myHeaderTag = (
        <h5 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h5>
      );
      break;
    case 6:
      myHeaderTag = (
        <h6 key={`HeadTags${text}_${title}${hSize}`} className={className}>
          {setMyText}
        </h6>
      );
      break;
    case 7:
      myHeaderTag = (
        <span key={`HeadTags${text}_${title}${hSize}`} className={`${className} ${styles.h7}`}>
          {setMyText}
        </span>
      );
      break;
    case 8:
      myHeaderTag = (
        <span key={`HeadTags${text}_${title}${hSize}`} className={`${className} ${styles.h8}`}>
          {setMyText}
        </span>
      );
      break;
    case 9:
      myHeaderTag = (
        <span key={`HeadTags${text}_${title}${hSize}`} className={`${className} ${styles.h9}`}>
          {setMyText}
        </span>
      );
      break;
    default:
      break;
  }
  return myHeaderTag;
}
