import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import useScreenSize from '../../../hooks/useScreenSize.js';

import { BackGroundColors, FontAwesomeIcons } from '../../../constants/ComponentEnums.jsx';
import Button from '../../Button/Button.jsx';

function Recommend() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  return (
    <Button
      key={'recommed.button'}
      id={'recommed.button'}
      icon={FontAwesomeIcons.faGift}
      text={isMobile ? '' : t('recommed')}
      outline
      bold
      iconSide={isMobile ? false : true}
      border
      type={BackGroundColors.Successbg}
      onClick={() => navigate('/gift-code')}
    />
  );
}

export default Recommend;
